







































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsAssignTagModal from '@/modules/lists/components/VsAssignTagModal/Index.vue'
import { removeTagToContact } from '@/api/consoleApi/recipients'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { orderBy, unionBy } from 'lodash'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'ContactTags',
    components: {
        VsConfirm,
        VsAssignTagModal,
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({ default: null, required: true }) list!: any
    @Prop({ default: null, required: true }) contact!: any

    private cachedTags: any[] = []
    public loading = false
    private tags = []
    private total = 0
    private filter = ''

    private pagination: any = {
        page: 1,
        itemsPerPage: 10,
        orderBy: null,
    }

    async beforeMount () {
        if (!this.hasTag) {
            this.$router.replace({
                name: 'contactDetails',
            })
        }
    }

    $refs!: any

    get listId () {
        return this.$route?.params?.listId
    }

    get user () {
        return UserModule.user
    }

    get hasTag () {
        return this.user.configuration.rules.tag
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get contactId () {
        return this.$route?.params?.contactId
    }

    get contactTags () {
        if (!this.contact || !this.contact.tags) return []
        return orderBy(
            this.contact.tags.data
                .filter((tag: any) => tag.tag_name.toLowerCase().includes(this.filter.toLowerCase())),
            'created_at',
            'desc',
        )
    }

    private async removeAssign (tagId: any) {
        try {
            await this.$refs.removeConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await removeTagToContact(this.listId, tagId, this.contactId)

            this.$root.$vsToast({
                heading: 'Etichetta rimossa con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.$emit('form-saved')
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    private setCachedTags (tags: any[]) {
        this.cachedTags = unionBy(
            this.cachedTags,
            tags
                .map((tag: any) => {
                    return {
                        value: tag.id,
                        label: tag.name,
                    }
                }),
            'value',
        )
    }
}
