








































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
    addTagToContact,
    createTag,
    getTags,
    removeTagToContact,
} from '@/api/consoleApi/recipients'
import VsTagNameModal from '@/modules/lists/components/VsTagNameModal/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'

@Component({
    name: 'VsAssignTagModal',
    components: {
        VsTagNameModal,
    },
})
export default class extends Vue {
    @Prop({
        default: () => [],
        required: false,
    }) assignedTagIds!: any[]

    @Prop({ required: false, default: () => [] }) cachedTags!: any[]

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) multi!: boolean

    private contactIds: number[] = []
    private type: 'add' | 'remove' = 'add'
    private show = false
    private loading = false
    private selectedTag = ''
    private selectedTagMulti = []
    private tags: any[] = []
    private count = 0
    private errors = 0
    $refs: any

    get listId () {
        return this.$route?.params?.listId || ''
    }

    private async save () {
        this.loading = true
        this.count = 0
        this.errors = 0
        try {
            if (this.type === 'add') {
                for (const contactId of this.contactIds) {
                    if (this.multi) {
                        for (const tag of this.selectedTagMulti) {
                            await this.attachTag(tag, contactId)
                        }
                    } else {
                        await this.attachTag(this.selectedTag, contactId)
                    }
                }
                if (this.count === 0 && this.errors > 0) {
                    this.$root.$vsToast({
                        heading: 'Errore durante l\'assegnazione dell\'etichette',
                        aspect: VsToastAspectEnum.alert,
                        timeout: 3000,
                    })
                } else {
                    this.$root.$vsToast({
                        heading: this.count === 1 ? 'Etichetta assegnata con successo' : `${this.count} etichette assegnate con successo`,
                        aspect: VsToastAspectEnum.success,
                        timeout: 3000,
                    })
                }
            } else {
                for (const contactId of this.contactIds) {
                    await removeTagToContact(this.listId, this.selectedTag, contactId)
                }
                this.$root.$vsToast({
                    heading: 'Etichetta rimossa con successo',
                    aspect: VsToastAspectEnum.success,
                    timeout: 3000,
                })
            }
            this.$emit('tag-assigned')
            this.show = false
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    // get tagsOptions () {
    //     return this.tags
    //         .map(el => {
    //             return {
    //                 label: el.name + ' (assegnato)',
    //                 value: el.id,
    //                 disabled: this.assignedTagIds.includes(el.id),
    //             }
    //         })
    // }

    get tagsOptions () {
        return this.tags.filter(el => !this.assignedTagIds.includes(el.id)).map(el => {
            return {
                label: el.name,
                value: el.id,
            }
        })
    }

    public async openModal (contactIds: any[], type?: 'add' | 'remove') {
        await this.getTags('')
        this.type = type || 'add'
        this.contactIds = contactIds
        this.selectedTag = ''
        this.selectedTagMulti = []
        this.show = true
    }

    private async getTags (search: string) {
        this.loading = true
        try {
            const resp = await getTags(
                this.listId,
                {
                    limit: 50,
                    search: `name:${search}`,
                    searchFields: 'name:like',
                })
            this.tags = resp.data.data
            this.$emit('cached-tags-changed', resp.data.data)
        } catch (e) {
            this.tags = []
        }
        this.loading = false
    }

    private async attachTag (tag: any, contactId: any) {
        try {
            await addTagToContact(this.listId, tag, contactId)
            this.count++
        } catch (e) {
            const error = get(e, 'response.data.error', '')
            if (error === 'ContactAlreadyAssociatedToTag') {
                this.count++
                return
            }
            this.errors++
        }
    }

    async createTag (e: any) {
        try {
            const data = {
                name: e.name,
            }
            await createTag(this.listId, data)
            this.$refs.tagNameModal.closeModal()
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Etichetta creato con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('tag-created')
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Qualcosa è andato storto',
                aspect: VsToastAspectEnum.alert,
            })
            this.$refs.tagNameModal.loading = false
            console.log(e)
        }
    }

    formatBadgeLabel (item: any) {
        return this.cachedTags.find((el: any) => el.value === item)?.label || `Etichetta con id ${item} non trovata`
    }
}
